/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Link } from "@wsui/base";

export default function BreadcrumbsLink(props) {
  return (
    <Link
      css={css`
        font-weight: 500;
      `}
      {...props}
    />
  );
}
