import { Html } from "@whitespace/gatsby-theme-wordpress-basic/src/wsui/components";
import {
  TeaserCard,
  CardContent,
  CardTitle,
  useThemeProps,
  Typography,
  TypographyBlock,
  Link,
} from "@wsui/base";
import React from "react";
import { useTranslation } from "react-i18next";

export default function SortingGuideHit(props) {
  props = useThemeProps({ props, name: "AlgoliaSortingGuideHit" });
  props = useThemeProps({ props, name: "AlgoliaHit" });
  let {
    hit,
    horizontalContentMaxWidth = "100%",
    hideImage,
    // eslint-disable-next-line no-unused-vars
    hitsOwnerState,
    ...restProps
  } = props;

  const { t } = useTranslation();

  const { title, contentTypeName, image, avfallExtraInformation } = hit;

  const {
    avfallBraAttVeta: tips,
    avfallFraktionAvc: fractionWasteCentral,
    avfallFraktionHemma: fractionHome,
  } = avfallExtraInformation || {};

  let contentTypeLabel = t(`contentTypes.${contentTypeName}.name`, {
    count: 1,
  });

  return (
    <TeaserCard
      link={undefined}
      title={title}
      image={hideImage ? undefined : image || {}}
      mirrored
      mediaColspan={3}
      as="li"
      hoverColor={"auto"}
      horizontalContentMaxWidth={horizontalContentMaxWidth}
      {...restProps}
    >
      <CardContent>
        <CardTitle />
        {avfallExtraInformation && (
          <div>
            {tips && (
              <TypographyBlock>
                <Html>{tips}</Html>
              </TypographyBlock>
            )}
            {fractionWasteCentral && (
              <div>
                <Typography variant="body">
                  <strong>På återvinningscentral: </strong>
                  <Link
                    color={"primary"}
                    href={fractionWasteCentral.url}
                    type={"internal"}
                  >
                    {fractionWasteCentral.name}
                  </Link>
                </Typography>
              </div>
            )}
            {fractionHome && (
              <div>
                <Typography variant="body">
                  <strong>Hemma: </strong>
                  <Link
                    color={"primary"}
                    href={fractionHome.url}
                    type={"internal"}
                  >
                    {fractionHome.name}
                  </Link>
                </Typography>
              </div>
            )}
          </div>
        )}
        <Typography variant="meta">{contentTypeLabel}</Typography>
      </CardContent>
    </TeaserCard>
  );
}
