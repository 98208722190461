import DefaultHit from "@whitespace/gatsby-theme-wordpress-basic/src/wsui/algolia/DefaultHit.jsx";
import { Box, MaybeFragment, createTheme, withDefaultProps } from "@wsui/base";
import "./index.css";
import { PromoTextModule } from "@wsui/municipio";
import camelCase from "lodash/fp/camelCase";
import upperFirst from "lodash/fp/upperFirst";

import PostHit from "../@whitespace/gatsby-theme-wordpress-basic/wsui/algolia/PostHit.jsx";
import SortingGuideHit from "../@whitespace/gatsby-theme-wordpress-basic/wsui/algolia/SortingGuideHit.jsx";
import PostsModuleHorizontalLayout from "../PostsModuleHorizontalLayout.jsx";

import BreadcrumbsDescription from "./components/BreadcrumbsDescription.jsx";
import BreadcrumbsLink from "./components/BreadcrumbsLink.jsx";
import PageBottom from "./components/PageBottom.jsx";
import PostsModuleTeaserItem from "./components/PostsModuleTeaserItem.jsx";

export default createTheme({
  typography: {
    fonts: {
      "Open Sans": {
        src: {
          normal:
            'url("/fonts/OpenSans-VariableFont_wdth,wght.ttf") format("truetype")',
          italic:
            'url("/fonts/OpenSans-Italix-VariableFont_wdth,wght.ttf") format("truetype")',
        },
      },
    },
    fontFamily: "Open Sans, Helvetica, sans-serif",
    maxLineLength: "70ch",
    variants: {
      h1: {
        fontSize: [8, 9],
        lineHeight: [10.5, 11.5],
        fontWeight: 700,
        marginBlockStart: 12.5,
        marginBlockEnd: 6.75,
      },
      h2: {
        fontSize: [6.25, 7],
        lineHeight: [8, 9.25],
        fontWeight: 700,
        marginBlockStart: 12.5,
        marginBlockEnd: 5,
      },
      h3: {
        fontSize: [5, 5.5],
        lineHeight: [7, 8.5],
        fontWeight: 700,
        marginBlockStart: 7.5,
        marginBlockEnd: 4,
      },
      h4: {
        fontSize: [4.5, 4.5],
        lineHeight: [7, 7],
        fontWeight: 600,
        marginBlockStart: 12.5,
        marginBlockEnd: 5,
      },
      body: {
        fontSize: [4, 4],
        lineHeight: [6.75, 6.75],
        marginBlockEnd: 4,
      },
      preamble: {
        fontSize: [5, 5.5],
        lineHeight: [7, 8.5],
        marginBlockEnd: 7.5,
      },
      caption: {
        fontSize: 4,
        lineHeight: 6.75,
        fontWeight: 700,
      },
    },
  },
  colors: {
    teal: { main: "#007688", dark: "#00414B", 754: "#00414B" },
    gray: "#D3D4D3",
    yellow: "#DEC74C",
    white: "#fff",
    black: "#121212",
    lavender: "#78509C",
    violet: "#4C54A0",
    blue: "#007CC2",
    turquoise: "#009BB0",
    aquamarine: "#00AF9B",
    red: "#F14A4A",
    error: "red",
    info: "blue",
    success: "blue",
    primary: "teal",
    border: "gray.200",
  },
  textColors: ["black", "white"],
  components: {
    Alert: {
      defaultProps: {
        iconMapping: {
          info: "info",
          warning: "attention",
          error: "attention",
        },
      },
    },
    AlertBanner: {
      defaultProps: {
        color: "yellow",
      },
    },
    Breadcrumbs: {
      defaultProps: {
        hideDescription: false,
        gap: 1,
        descriptionGap: 1,
        components: {
          Description: BreadcrumbsDescription,
          Link: BreadcrumbsLink,
        },
      },
    },
    Clickable: {
      defaultProps: {
        hideExternalIcon: true,
      },
    },
    DefaultTemplate: {
      defaultProps: {
        topOffset: [10, 18],
      },
    },
    Details: {
      defaultProps: {
        printable: true,
      },
    },
    TreeMenu: {
      defaultProps: {
        collapseIcon: "close-sub-menu-white-icon",
        expandIcon: "open-sub-menu-teal-icon",
      },
    },
    TreeMenuItem: {
      defaultProps: {
        color: "teal",
        hoverColor: "turquoise",
      },
    },
    TreeMenuItemHeader: {
      defaultProps: {
        activeColor: "teal",
      },
    },
    TreeMenuLink: {
      defaultProps: {
        fontWeight: 700,
        fontSize: "16px",
        padding: "1.25rem",
      },
    },
    TreeMenuToggleButton: {
      defaultProps: {
        offset: "1.25rem",
      },
    },
    SlidePanelAction: {
      defaultProps: {
        color: "teal",
      },
    },
    Box: {
      defaultProps: {
        autoColor: "gray.50",
        borderRadius: 2.5,
      },
    },
    Button: {
      defaultProps: {
        variant: "default",
        style: {
          paddingBlock: 5,
          paddingInline: 7.5,
          borderRadius: 2.5,
          fontWeight: 700,
          transition: "0.22s ease",
          gap: "0.5rem",
        },
      },
      variants: {
        primary: {
          style: {
            paddingBlock: 3.5,
            backgroundColor: "yellow",
            color: "black",
          },
          hoverStyle: {
            backgroundColor: "yellow.100",
            color: "black",
          },
        },
        secondary: {
          style: {
            paddingBlock: 3.5,
            backgroundColor: "lavender.200",
            color: "black",
          },
          hoverStyle: {
            backgroundColor: "lavender.100",
            color: "black",
          },
        },
        default: {
          style: {
            backgroundColor: "gray.200",
            color: "black",
          },
          hoverStyle: {
            backgroundColor: "gray.100",
            color: "black",
          },
        },
        toggle: {
          style: {
            backgroundColor: "blue.50",
            color: "black",
            fontWeight: 500,
          },
          hoverStyle: {
            backgroundColor: "blue.100",
            color: "black",
          },
          activeStyle: {
            backgroundColor: "blue",
            color: "white",
          },
        },
        pagination: {
          style: {
            paddingBlock: 2,
            paddingInline: 4,
            backgroundColor: "gray.150",
            color: "black",
            fontWeight: 500,
          },
          hoverStyle: {
            backgroundColor: "gray.200",
            color: "black",
          },
          activeStyle: {
            backgroundColor: "blue",
            color: "white",
          },
        },
        submit: {
          style: {
            paddingBlock: 3.5,
          },
          color: "yellow",
        },
      },
    },
    Card: {
      defaultProps: {
        autoColor: "gray.50",
        borderRadius: 2.5,
      },
    },
    ConfirmDialog: {
      defaultProps: {
        confirmButtonProps: {
          style: {
            paddingBlock: 3,
            paddingInline: 7.5,
          },
        },
        denyButtonProps: {
          variant: "primary",
          style: {
            paddingBlock: 3,
            paddingInline: 7.5,
          },
        },
      },
    },
    CookieDialog: {
      defaultProps: {
        color: "gray.50",
      },
    },
    TeaserCard: {
      defaultProps: {
        color: "white",
        autoColor: "gray.50",
        padding: 5,
      },
    },
    MunicipioPostsModule: {
      defaultProps: {
        itemColor: "gray.50",
        displayModeMapping: { horizontal: PostsModuleHorizontalLayout },
      },
    },
    MunicipioPostsModuleItem: {
      defaultProps: {
        variantMapping: {
          teaser: PostsModuleTeaserItem,
        },
      },
    },
    MunicipioPostsModuleCardItem: {
      defaultProps: {
        components: {
          Button: {
            variant: "primary",
          },
        },
        style: {
          height: "100%", // TODO: Remove this when the height is fixed in WSUI
        },
      },
    },
    MunicipioPostsModuleGridLayout: {
      defaultProps: {
        className: "wsui-screen-only",
      },
    },
    MunicipioPostsModuleListLayout: {
      defaultProps: {
        itemVariant: "teaser",
        divider: false,
        spacing: 3,
      },
    },
    MunicipioPostsModuleArchiveLink: {
      defaultProps: {
        variant: "primary",
        align: "end",
      },
    },
    PageRightSidebarAreaModules: {
      defaultProps: {
        headingVariant: "h3",
      },
    },
    PageTopSidebarModules: {
      defaultProps: {
        components: {
          PageSection: {
            style: {
              // Makes the top sidebar overlap the header
              [`&:first-of-type[data-wsui-background-color-start="transparent"]`]:
                {
                  marginBlockStart: 0,
                  "&:before": {
                    content: `""`,
                    display: `block`,
                    height: `3rem`,
                    backgroundColor: `primary`,
                    marginBottom: `-2rem`,
                  },
                  "@media (min-width: 680px)": {
                    "&:before": {
                      content: `""`,
                      display: `block`,
                      height: `3rem`,
                      backgroundColor: `primary`,
                      marginBottom: `-3rem`,
                    },
                  },
                  "@media (min-width: 1000px)": {
                    "&:before": {
                      content: `""`,
                      display: `block`,
                      height: `5rem`,
                      backgroundColor: `primary.dark`,
                      marginBottom: `-5rem`,
                    },
                  },
                },
            },
          },
        },
      },
    },
    MunicipioTextModuleController: {
      defaultProps: {
        componentMapping: (props, defaultComponentMapping) =>
          props.displayMode === "promo"
            ? PromoTextModule
            : defaultComponentMapping(props),
      },
    },
    PageBreadcrumbs: {
      defaultProps: { spacingStart: [5, 6] },
    },
    PageBottomSidebarModules: {
      defaultProps: {
        components: {
          PageSection: {
            style: {
              // Makes the last page section’s background melt into the footer illustration
              [`&:last-of-type`]: {
                paddingBlockEnd: "calc(40px + 2.5vw + 10vw)",
                marginBlockEnd: "-10vw",
              },
            },
          },
        },
      },
    },
    ModularityArea: {
      defaultProps: {
        gap: [8.75, 17.5],
      },
    },
    PageSection: {
      defaultProps: {
        spacing: [8.75, 17.5],
      },
    },
    AlgoliaHits: {
      defaultProps: {
        divider: false,
      },
    },
    AlgoliaHit: {
      defaultProps: {
        color: "auto",
        hideImage: true,
        horizontalContentMaxWidth: "100%",
      },
    },
    AlgoliaHitController: {
      defaultProps: {
        componentMapping: ({ hit }) => {
          let contentType = hit.contentType?.name;
          return [`${upperFirst(camelCase(contentType))}Hit`];
        },
        components: {
          DefaultHit,
          PostHit,
          SortingGuideHit,
        },
      },
    },
    AlgoliaMenuButtonGroup: {
      defaultProps: {
        buttonProps: {
          variant: "toggle",
          style: {
            paddingBlock: 3,
            paddingInline: 5,
          },
        },
      },
    },
    AlgoliaPagination: {
      defaultProps: {
        align: "end",
      },
    },
    MunicipioFormModuleController: {
      defaultProps: {
        components: {
          DisclaimerWrapper: MaybeFragment,
        },
      },
    },
    MunicipioFormModule: {
      defaultProps: {
        components: {
          Wrapper: withDefaultProps(Box, { color: "blue.50" }),
        },
      },
    },
    MunicipioImageModule: {
      defaultProps: {
        imageProps: {
          imgProps: {
            style: {
              borderRadius: 2.5,
            },
          },
        },
      },
    },
    FormModuleCustomContentField: {
      defaultProps: {
        color: "transparent",
      },
    },
    TableModule: {
      defaultProps: {
        components: {
          Table: {
            color: "blue.50",
            stripedColor: "-25",
            borderRadius: 2.5,
            hoverColor: "white",
          },
        },
      },
    },
    Template: {
      defaultProps: {
        components: {
          PageBottom,
        },
      },
    },
    PageHeading: {
      defaultProps: {
        showDates: false,
      },
    },
  },
});
