/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Clickable } from "@wsui/base";
import { graphql, useStaticQuery } from "gatsby";

import mask from "../../../../images/header-logo-mask.svg";
import logo from "../../../../images/logo-primary.svg";

export default function HeaderLogo({ link, ...restProps }) {
  link = typeof link === "string" ? { href: link } : link;
  let title = useStaticQuery(graphql`
    query HeaderLogoQuery_Nsr {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)?.site?.siteMetadata?.title;
  return (
    <div
      {...restProps}
      css={css`
        position: relative;
        display: inline-flex;
        padding-top: 23px;
        padding-bottom: 18px;

        @media not print {
          &::after {
            content: "";
            position: absolute;
            top: 13px;
            left: calc(100% - 5.5px);
            bottom: 0;
            width: 100vw;
            background-color: #007688;
            background-image: url(${mask});
            background-size: auto 100%;
            background-position: -0.3px 0;
            background-repeat: no-repeat;
          }
        }
      `}
    >
      <Clickable
        {...link}
        css={css`
          color: inherit;
          text-decoration: inherit;
          position: relative;
          z-index: 1;
        `}
      >
        <img
          src={logo}
          alt={title}
          css={css`
            display: block;

            @media (max-width: 680px) {
              max-width: 151px;
            }
          `}
        />
      </Clickable>
    </div>
  );
}
