/** @jsx jsx */
import { css, jsx, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { useLocation } from "@gatsbyjs/reach-router";
import HeaderLogo from "@whitespace/gatsby-theme-wordpress-basic/src/wsui/components/HeaderLogo.jsx";
import {
  Clickable,
  Icon,
  PageGrid,
  PageGridItem,
  Link,
  useThemeProps,
  usePrevious,
  Button,
} from "@wsui/base";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import HeaderMainMenu from "../../../../wsui/components/HeaderMainMenu.jsx";
import HeaderMainMenuSecondary from "../../../../wsui/components/HeaderMainMenuSecondary.jsx";
import HeaderSearchField from "../../../../wsui/components/HeaderSearchField.jsx";

import HeaderHamburgerMenu from "./HeaderHamburgerMenu.jsx";

const SkipToMainLink = styled(Button)`
  position: absolute;
  left: 50%;
  transform: translateY(-550%);
  transition: transform 0.3s;
  z-index: 1;
  &:focus {
    transform: translateY(0%);
  }
`;

export default function Header(inProps) {
  const {
    bgColorPrimary = "primary",
    bgColorSecondary = "primary.dark",
    className,
    ...restProps
  } = useThemeProps({
    props: inProps,
    name: "Header",
  });
  const { t } = useTranslation();

  const theme = useTheme();
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const location = useLocation();
  const prevLocation = usePrevious(location);

  useEffect(() => {
    document.body.style.overflow = hamburgerOpen ? "hidden" : "";
  }, [hamburgerOpen]);

  useEffect(() => {
    if (location !== prevLocation) {
      setHamburgerOpen(false);
    }
  }, [location, prevLocation]);

  return (
    <header
      css={css`
        position: relative;
      `}
      className={clsx("wsui-screen-only", className)}
      {...restProps}
    >
      <SkipToMainLink variant="primary" href="#main">
        Gå direkt till innehåll
      </SkipToMainLink>
      <PageGrid
        css={css`
          overflow: hidden;
          border-bottom: 1rem solid ${theme.getColor(bgColorPrimary)};

          @media (min-width: 680px) {
            border-bottom: none;
          }
        `}
      >
        <PageGridItem colspan={12}>
          <div
            css={css`
              display: flex;
            `}
          >
            <HeaderLogo link="/" />
          </div>
        </PageGridItem>
      </PageGrid>
      <PageGrid
        css={css`
          background: ${theme.getColor(bgColorPrimary)};
          color: ${theme.getColor([bgColorPrimary, "text"])};

          @media (max-width: 680px) {
            background: none;
            position: absolute;
            top: 23px;
            right: 0;
          }
        `}
      >
        <PageGridItem colspan={12}>
          <div
            css={css`
              display: grid;
              grid-template-columns: auto minmax(min-content, 36rem) max-content;
              grid-template-areas: "main-menu search flyout";
              gap: 1.25rem;
              padding-top: 1px;
              padding-bottom: 1rem;
            `}
          >
            {/* TODO: Implement header content with WSUI components */}
            {/* <SkipTo /> */}
            <HeaderMainMenu
              css={css`
                grid-area: main-menu;

                @media (max-width: 680px) {
                  display: none;
                }
              `}
            />
            <HeaderSearchField
              css={css`
                grid-area: search;

                @media (max-width: 840px) {
                  display: none;
                }
              `}
            />
            <div
              css={css`
                grid-area: search;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                @media (min-width: 841px) {
                  display: none;
                }
              `}
            >
              <Link
                uri="/sok"
                css={css`
                  display: flex;
                  flex-flow: column;
                  align-items: center;
                  color: white;
                  text-decoration: none;
                `}
              >
                <Icon name="search-alt" size="2rem" />
                <span
                  css={css`
                    font-size: ${theme.getLength(3.25)};
                    line-height: ${theme.getLength(5)};
                    font-weight: bold;

                    @media (min-width: 680px) {
                      display: none;
                    }
                  `}
                >
                  Sök
                </span>
              </Link>
            </div>

            <Clickable
              onClick={() => {
                setHamburgerOpen(true);
              }}
              aria-label={t("menu")}
              aria-expanded={String(hamburgerOpen)}
              aria-controls={"header-hamburger-menu"}
              css={css`
                grid-area: flyout;
                ${theme.styleUtils.buttonReset}
                align-self: center;
                cursor: pointer;
                display: flex;
                flex-flow: column;
                align-items: center;
                color: white;
                text-decoration: none;
              `}
            >
              <Icon name="menu" size="2rem" />
              <span
                css={css`
                  font-size: ${theme.getLength(3.25)};
                  line-height: ${theme.getLength(5)};
                  font-weight: bold;

                  @media (min-width: 680px) {
                    display: none;
                  }
                `}
              >
                Meny
              </span>
            </Clickable>
          </div>
        </PageGridItem>
      </PageGrid>
      <PageGrid
        css={css`
          display: none;
          background: ${theme.getColor(bgColorSecondary)};
          color: ${theme.getColor([bgColorSecondary, "text"])};
          /* padding-bottom: 4rem;
          margin-bottom: -4rem; */

          @media (min-width: 1000px) {
            display: flex;
          }
        `}
      >
        <PageGridItem colspan={12}>
          <HeaderMainMenuSecondary aria-label={t("mainMenu")} />
        </PageGridItem>
      </PageGrid>
      <HeaderHamburgerMenu
        open={hamburgerOpen}
        onClose={() => setHamburgerOpen(false)}
        id={"header-hamburger-menu"}
      />
    </header>
  );
}
