import { css, useTheme } from "@emotion/react";
import { Box, Button } from "@wsui/base";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import DayOpeningHours from "../../../../../wsui/components/DayOpeningHours.jsx";
import ExceptionsOpeningHours from "../../../../../wsui/components/ExceptionsOpeningHours.jsx";
import WeekOpeningHours from "../../../../../wsui/components/WeekOpeningHours.jsx";

import ModuleWrapper from "./ModuleWrapper.jsx";

export default function OpenHoursModule({
  module = {},
  title,
  description,
  color = "auto",
  autoColor = "gray.50",
  ...restProps
}) {
  const theme = useTheme();
  let { modOpeningHoursOptions: { layout, link, place, displayPlaces } = {} } =
    module;

  let places =
    useStaticQuery(graphql`
      query WP_OpeningHours {
        wp {
          ...WP_OpeningHoursForHook
        }
      }
    `)?.wp?.acfOptionsOpeningHours?.openingHoursOptions?.places || [];

  if (displayPlaces) {
    places = places.filter(({ name }) => displayPlaces.includes(name));
  }

  place = place && places.find(({ name }) => name === place);

  let content;
  switch (layout) {
    case "week":
      content = (
        <WeekOpeningHours
          defaultOpenHours={place?.defaultOpenHours}
          exceptions={place?.exceptions}
        />
      );
      break;
    case "today":
      content = <DayOpeningHours places={places} />;
      break;
    case "exceptions":
      content = <ExceptionsOpeningHours exceptions={place?.exceptions} />;
      break;
  }

  if (!content) {
    return null;
  }

  return (
    <ModuleWrapper title={title} description={description} {...restProps}>
      <Box borderRadius={4} padding={7.5} color={color} autoColor={autoColor}>
        {content}
      </Box>
      {link && (
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
          `}
        >
          <Button
            variant="primary"
            to={link.url}
            target={link.target}
            css={css`
              margin-top: ${theme.getLength(6)};
              width: auto;
              text-align: center;
              box-sizing: border-box;
            `}
          >
            {link.title}
          </Button>
        </div>
      )}
    </ModuleWrapper>
  );
}
