/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useSiteMetadata } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/page-context";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from 'react-helmet';


export default function Seo({ title, isFrontPage, description }) {
  const {
    wp: { seo:globalSeoSettings },
} = useStaticQuery(graphql`
    query SiteInfoQuery {
        wp {
            seo {
                schema {
                    companyName
                    inLanguage
                }
            }
        }
    }
`);
    const { title: titleFromPage, seo, contentType, featuredImage } = usePageContext();
    const { title: siteTitle, author } = useSiteMetadata();

    const inLanguage = globalSeoSettings?.schema?.inLanguage;
    const schema = globalSeoSettings?.schema;
    const metaTitle = title || seo?.title || titleFromPage;
    const descriptionFromSeo = seo?.metaDesc ? seo?.metaDesc : seo?.opengraphDescription;
    const metaDescription = descriptionFromSeo || description;

    const robotsIndex = seo?.metaRobotsNoindex === 'noindex' ? 'noindex' : 'index';
    const robotsFollow = seo?.metaRobotsNofollow === 'nofollow' ? 'nofollow' : 'follow';

    const getReadingTime = time => (time === 1 ? '1 minute' : `${time} minutes`);

    const image = featuredImage?.node;
    const ogImage = seo?.opengraphImage;
    const twitterImage = seo?.twitterImage;
    const opengraphContentType = contentType?.name === "post" ? "article": "website";


    return (
        <Helmet
            htmlAttributes={{
                lang: inLanguage,
            }}
            titleTemplate={isFrontPage ? siteTitle : `%s – ${siteTitle}`}
            defaultTitle={siteTitle}
            title={metaTitle}
            meta={[
                {
                    name: `robots`,
                    content: `max-snippet:-1, max-image-preview:large, max-video-preview:-1, ${robotsIndex}, ${robotsFollow}`,
                },
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:site_name`,
                    content: schema?.companyName,
                },
                {
                    property: `og:title`,
                    content: seo?.opengraphTitle || metaTitle,
                },
                {
                    property: `og:description`,
                    content: seo?.opengraphDescription,
                },
                {
                    property: `og:locale`,
                    content: seo?.schema?.inLanguage ? seo.schema.inLanguage.replace('-', '_') : null,
                },
                {
                    property: `og:type`,
                    content: opengraphContentType,
                },
                {
                    property: 'og:image',
                    content: ogImage?.src || image?.src
                },
                {
                    property: 'og:image:alt',
                    content: ogImage?.src ? ogImage.alt : image?.alt
                },
                {
                    name: `twitter:title`,
                    content: seo?.twitterTitle || seo?.opengraphTitle  || metaTitle,
                },
                {
                    name: `twitter:description`,
                    content: seo?.twitterDescription || metaDescription,
                },
                {
                  name: `twitter:creator`,
                  content: author,
                },
                {
                    name: 'twitter:image',
                    content: twitterImage?.src  || image?.src,
                },
                {
                    name: 'twitter:image:alt',
                    content: twitterImage?.src ? seo?.twitterImage?.alt : image?.alt,
                },
                {
                    name: 'twitter:label1',
                    content: seo?.readingTime ? 'Estimated reading time' : null,
                },
                {
                    name: 'twitter:data1',
                    content: seo?.readingTime ? getReadingTime(+seo.readingTime) : '',
                },
                {
                  name: `referrer`,
                  content: `no-referrer`,
                },
                {
                  name: `apple-mobile-web-app-capable`,
                  content: `yes`,
                },
                {
                  name: `apple-mobile-web-app-status-bar-style`,
                  content: `white`,
                }
            ]
                .filter(m => !!m.content)}
            encodeSpecialCharacters={false}
         />
    );
}
