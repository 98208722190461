/** @jsx jsx */
import { css, jsx, useTheme } from "@emotion/react";
import { useLocation } from "@gatsbyjs/reach-router";
import usePages from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/pages";
import {
  getChildren,
  getTopLevelPages,
} from "@whitespace/gatsby-theme-wordpress-basic/src/utils/pageTree";
import { useThemeProps, Typography, Link } from "@wsui/base";
import PropTypes from "prop-types";

HeaderMainMenuSecondary.propTypes = {
  menuName: PropTypes.string,
  color: PropTypes.string,
};

export default function HeaderMainMenuSecondary(props) {
  const theme = useTheme();
  props = useThemeProps({ name: "HeaderMainMenuSecondary", props });
  let { bgColor = "primary", ...restProps } = props;

  const allPages = usePages();
  const location = useLocation();
  const { pathname } = location;

  const frontPage = allPages.find((page) => page.isFrontPage);
  const activeTopLevel =
    getTopLevelPages(allPages)
      .filter((item) => !!item.showInMenu && !item.isRestricted)
      .find(
        (page) => page.uri === `/${pathname.split("/").filter(Boolean)?.[0]}/`,
      ) ||
    ((pathname === "/" ||
      pathname.includes("/privat") ||
      pathname.includes("/wp-preview")) &&
      frontPage);

  if (!activeTopLevel) {
    return null;
  }

  let items = getChildren(allPages, activeTopLevel.id).filter(
    (item) => !!item.showInMenu && !item.isRestricted,
  ); // Get children of active top level item

  return (
    <Typography
      variant="h4"
      as="nav"
      css={css`
        position: relative;
        padding-block: 0.84375rem;
        max-width: none;
      `}
    >
      <ul
        css={css`
          display: flex;
          list-style: none;
          padding-left: 0;
          margin: 0;
          column-gap: calc(1.875rem - 20px); /* Adjust for link padding */
        `}
        {...restProps}
      >
        {items.map((item, index) => {
          return (
            <li key={index}>
              <Link
                partiallyActive={true}
                activeClassName={"is-active"}
                to={item.uri || item.url}
                css={css`
                  display: block;
                  color: white;
                  text-decoration: none;
                  padding: 3px 10px;
                  border-radius: 6px;
                  transition: background-color 0.22s ease;

                  &.is-active {
                    background-color: ${theme.getColor(bgColor)};
                  }

                  &:not(.is-active):hover,
                  &:not(.is-active):focus-visible {
                    background-color: ${theme.getColor(bgColor)}80;
                  }
                `}
              >
                {item.label || item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </Typography>
  );
}
