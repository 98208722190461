/** @jsx jsx */
import { css, jsx, useTheme } from "@emotion/react";
import { useThemeProps, Icon, Button } from "@wsui/base";

export default function SearchField(props) {
  // eslint-disable-next-line no-unused-vars
  const theme = useTheme();
  props = useThemeProps({ name: "SearchField", props });
  let { resultType, placeholder = "Vad letar du efter?", ...restProps } = props;

  return (
    <form
      method="GET"
      action={resultType ? `/${resultType}` : "/sok"}
      css={css``}
      {...restProps}
    >
      <label
        htmlFor="search-field"
        css={css`
          position: absolute;
          clip: rect(0, 0, 0, 0);
          pointer-events: none;
        `}
      >
        Sök
      </label>
      <div
        css={css`
          position: relative;
          display: grid;
          align-items: center;

          & > * {
            grid-column: 1;
            grid-row: 1;
          }
        `}
      >
        <Icon
          name="search"
          size={4}
          css={css`
            color: #00414b;
            pointer-events: none;
            margin-left: 1.25rem;
          `}
        />
        <input
          id="search-field"
          type="search"
          name="query"
          placeholder={placeholder}
          autoComplete="off"
          css={css`
            padding: 1rem 2rem 1rem 2.875rem;
            border: none;
            border-radius: 6px;
            font-size: inherit;
            &::-webkit-search-decoration {
              display: none;
            }
          `}
        />
        <Button
          type="submit"
          variant="submit"
          css={css`
            position: absolute;
            inset-block: 0;
            inset-inline-end: 0;
            margin: ${theme.getLength(1)};
            aspect-ratio: 1;
            height: calc(100% - 2 * ${theme.getLength(1)});
            padding-block: 0;
            padding-inline: ${theme.getLength(7.5)};
          `}
        >
          Sök
        </Button>
      </div>
    </form>
  );
}
