/** @jsx jsx */
import { jsx, css, useTheme } from "@emotion/react";
import {
  Button,
  Icon,
  PageGrid,
  PageGridItem,
  PageSection,
  useThemeProps,
} from "@wsui/base";

export default function PageFooter(props) {
  // eslint-disable-next-line no-unused-vars
  const theme = useTheme();
  props = useThemeProps({ props, name: "PageFooter" });
  let { ownerState, ...restProps } = props;

  let { hasMainContent, defaultColspan, hasBottomSidebarModules, hasSidebar } =
    ownerState;

  if (!hasMainContent) {
    return null;
  }

  return (
    <PageSection backgroundColor="transparent" {...restProps}>
      <PageGrid>
        <PageGridItem
          colspan={hasBottomSidebarModules || hasSidebar ? 12 : defaultColspan}
        >
          <Button
            css={css`
              padding-block: ${theme.getLength(3)};
              padding-inline: ${theme.getLength(7.5)};
            `}
            onClick={() => window.print()}
            className="wsui-screen-only"
            variant={null}
            color="gray.50"
          >
            <Icon name="print" size={"1.5em"} />
            Skriv ut
          </Button>
        </PageGridItem>
      </PageGrid>
    </PageSection>
  );
}
