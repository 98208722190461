/** @jsx jsx */
import { css, jsx, useTheme } from "@emotion/react";
import { useThemeProps, Icon } from "@wsui/base";
import PropTypes from "prop-types";

HeaderSearchField.propTypes = {
  menuName: PropTypes.string,
  color: PropTypes.string,
  resultType: PropTypes.string,
};

export default function HeaderSearchField(props) {
  const theme = useTheme();
  props = useThemeProps({ name: "HeaderSearchField", props });
  let { resultType, ...restProps } = props;

  return (
    <form
      method="GET"
      action={resultType ? `/${resultType}` : "/sok"}
      css={css``}
      {...restProps}
    >
      <label
        htmlFor="site-search"
        css={css`
          position: absolute;
          clip: rect(0, 0, 0, 0);
          pointer-events: none;
        `}
      >
        Sök
      </label>
      <div
        css={css`
          position: relative;
          display: grid;
          align-items: center;

          & > * {
            grid-column: 1;
            grid-row: 1;
          }
        `}
      >
        <Icon
          name="search"
          size={4}
          css={css`
            color: #00414b;
            pointer-events: none;
            margin-left: 1.25rem;
          `}
        />
        <input
          id="site-search"
          type="search"
          name="query"
          placeholder="Vad letar du efter?"
          autoComplete="off"
          css={css`
            padding: 1rem 2rem 1rem 2.875rem;
            border: none;
            border-radius: 6px;
            font-size: inherit;
          `}
        />
      </div>
      <button
        type="submit"
        css={css`
          position: absolute;
          clip: rect(0, 0, 0, 0);
          pointer-events: none;
        `}
      >
        Sök
      </button>
    </form>
  );
}
