/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useThemeProps } from "@wsui/base";
import formatDate from "date-fns/format";
import { sv } from "date-fns/locale";

import Table from "./Table.jsx";

export default function ExceptionsOpeningHours(props) {
  const { exceptions, ...restProps } = useThemeProps({
    name: "ExceptionsOpeningHours",
    props,
  });

  const today = new Date().setHours(0, 0, 0, 0);

  const items = (exceptions || [])
    .filter((exception) => new Date(exception.date) >= today)
    .map(({ description, date, openHours }) => {
      const shortDate = formatDate(new Date(date), "d MMM", { locale: sv });
      return {
        name: `${description} (${shortDate})`,
        time: openHours,
      };
    });

  // TODO: What to do if there are no items?

  return <Table rows={items} {...restProps} />;
}
