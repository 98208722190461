/** @jsx jsx */
import { css, jsx, useTheme } from "@emotion/react";
import { useLocation } from "@gatsbyjs/reach-router";
import usePages from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/pages";
import { getTopLevelPages } from "@whitespace/gatsby-theme-wordpress-basic/src/utils/pageTree";
import { useThemeProps, Typography, Link } from "@wsui/base";
import PropTypes from "prop-types";

HeaderMainMenu.propTypes = {
  menuName: PropTypes.string,
  parentItem: PropTypes.object,
  color: PropTypes.string,
};

export default function HeaderMainMenu(props) {
  const theme = useTheme();
  props = useThemeProps({ name: "HeaderMainMenu", props });
  let { activeIndicator = "yellow", ...restProps } = props;

  const allPages = usePages();
  const location = useLocation();
  const { pathname } = location;

  let items = getTopLevelPages(allPages, true)?.filter(
    (item) => !!item.showInMenu && !item.isRestricted,
  );

  return (
    <Typography
      variant="h4"
      as="nav"
      css={css`
        position: relative;
        padding-block: 1rem 0;
        max-width: none;
      `}
    >
      <ul
        css={css`
          display: flex;
          list-style: none;
          padding-left: 0;
          margin: 0;
          column-gap: 1.875rem;
        `}
        {...restProps}
      >
        {items.map((item, index) => {
          return (
            <li key={index}>
              <Link
                partiallyActive={true}
                activeClassName={
                  item.uri === "/" && pathname !== "/" ? null : "is-active"
                }
                className={
                  item.uri === "/" &&
                  (pathname.includes("/privat") ||
                    pathname.includes("/wp-preview")) &&
                  "is-active"
                }
                to={item.uri || item.url}
                css={css`
                  display: block;
                  color: white;
                  text-decoration: none;

                  &::after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 6px;
                    margin-top: 2px;
                    border-radius: 10px;
                    opacity: 0;
                    transform: scaleX(0);
                    background-color: ${theme.getColor(activeIndicator)};
                    transition: all 0.22s ease;
                  }

                  &.is-active,
                  &:hover,
                  &:focus-visible {
                    &::after {
                      opacity: 1;
                      transform: scaleX(1);
                    }
                  }
                `}
              >
                {item.label || item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </Typography>
  );
}
