/** @jsx jsx */
import { css, jsx, useTheme } from "@emotion/react";
import { HTML } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import { PageGrid, PageGridItem, useThemeProps } from "@wsui/base";
import clsx from "clsx";
import { graphql, useStaticQuery } from "gatsby";

import facebookIcon from "../../../../images/facebook-icon.svg";
import topImage from "../../../../images/footer-top.svg";
import linkedinIcon from "../../../../images/linkedin-icon.svg";
import logo from "../../../../images/logo-footer.svg";

import FooterMenu from "./FooterMenu.jsx";

export default function Footer(inProps) {
  const {
    color = "primary",
    className,
    ...restProps
  } = useThemeProps({
    props: inProps,
    name: "Footer",
  });

  const theme = useTheme();

  const data = useStaticQuery(graphql`
    query FooterContent_NSR {
      wp {
        acfOptionsFooter {
          footerContent {
            contact
            address
          }
        }
      }
    }
  `);

  return (
    <footer className={clsx("wsui-screen-only", className)} {...restProps}>
      <img
        css={css`
          display: block;
          width: 100%;
          position: relative;
          margin-bottom: -1px;
        `}
        src={topImage}
        alt=""
      />
      <div
        css={css`
          background: ${theme.getColor(color)};
          color: ${theme.getColor([color, "text"])};
          padding-block: 6rem 2rem;
        `}
      >
        <PageGrid
          css={css`
            display: flex;
            flex-direction: row;
            a {
              color: ${theme.getColor([color, "text"])};
            }
            h2 {
              margin-bottom: 8px;
            }
            .footer-social-media a {
              text-decoration: none;
              margin: 0 6px;
            }
            .facebook-icon {
              background-image: url(${facebookIcon});
              display: inline-block;
              height: 30px;
              width: 30px;
            }
            .linkedin-icon {
              background-image: url(${linkedinIcon});
              display: inline-block;
              height: 30px;
              width: 30px;
            }
          `}
        >
          <PageGridItem colspan={12}>
            <HTML>{data?.wp?.acfOptionsFooter?.footerContent?.contact}</HTML>
          </PageGridItem>
          <PageGridItem colspan={12}>
            <HTML>{data?.wp?.acfOptionsFooter?.footerContent?.address}</HTML>
          </PageGridItem>
          <PageGridItem colspan={12}>
            <h2>Snabblänkar</h2>
            <FooterMenu inProps={{"aria-label": "Snabblänkar"}} />
            {/* TODO: Implement footer links menu */}
            {/*<FooterShortcutMenu aria-label={t("mainMenu")} /> */}
          </PageGridItem>
        </PageGrid>
        <PageGrid>
          <PageGridItem colspan={12}>
            <div
              css={css`
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
              `}
            >
              <img
                css={css`
                  width: 100%;
                  max-width: 200px;
                `}
                src={logo}
                alt=""
              />
            </div>
          </PageGridItem>
        </PageGrid>
      </div>
    </footer>
  );
}
