import { css, useTheme } from "@emotion/react";
import { Box, useThemeProps } from "@wsui/base";
import omit from "lodash/fp/omit";
import React from "react";

import SearchField from "../../../../../wsui/components/SearchField.jsx";

import ModuleWrapper from "./ModuleWrapper.jsx";

export default function SortingGuideModule(props) {
  const theme = useTheme();
  props = useThemeProps({ props, name: "SortingGuideModule" });
  let {
    // module = {},
    title,
    description,
    color = "auto",
    autoColor = "gray.50",
    ...restProps
  } = omit(["module"], props);
  return (
    <ModuleWrapper title={title} description={description} {...restProps}>
      <Box padding={4} color={color} autoColor={autoColor}>
        <SearchField
          resultType="sorteringsguide"
          placeholder="Sök i Sorteringsguiden"
          css={css`
            border: 1px solid;
            border-radius: 6px;
            border-color: ${theme.getColor("border.300")};
          `}
        />
      </Box>
    </ModuleWrapper>
  );
}
