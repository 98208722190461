/** @jsx jsx */
import { css, jsx, useTheme } from "@emotion/react";
import {
  TeaserCard,
  CardContent,
  CardTitle,
  CardMeta,
  Button,
  Typography,
  useThemeProps,
  Excerpt,
} from "@wsui/base";
import { postsModuleContext } from "@wsui/municipio";
import { pickAll } from "lodash/fp";
import { useContext } from "react";

import PostTime from "./PostTime.jsx";

export default function PostsModuleTeaserItem(props) {
  props = useThemeProps({ props, name: "MunicipioPostsModuleTeaserItem" });
  let {
    item,
    visibleFields,
    dateFormat = {
      year: "numeric",
      month: "long",
      day: "numeric",
    },
    ...restProps
  } = props;

  const theme = useTheme();

  let link = item.link;
  let cta = link?.title ? link : null;
  link = cta ? null : link;

  item = pickAll(visibleFields, item);

  const { itemColor } = useContext(postsModuleContext);

  return (
    <TeaserCard
      title={item.title}
      link={link}
      color={itemColor}
      css={css`
        height: 100%;
      `}
      {...restProps}
    >
      <CardContent
        css={css`
          grid-template-columns: auto 1fr;
          column-gap: ${theme.getLength(5)};
        `}
      >
        {item.date && (
          <CardMeta
            css={css`
              flex: none;
              align-self: start;
            `}
          >
            <PostTime date={item.date} format={dateFormat} />
          </CardMeta>
        )}
        <div>
          <CardTitle variant="h4" />
          {item.description ? (
            <Excerpt lines={1}>
              <Typography variant="description">{item.description}</Typography>
            </Excerpt>
          ) : null}
          {cta ? (
            <Button
              css={css`
                justify-self: start;
              `}
              {...cta}
            />
          ) : null}
        </div>
      </CardContent>
    </TeaserCard>
  );
}
