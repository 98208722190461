import {
  TeaserCard,
  CardContent,
  CardTitle,
  useThemeProps,
  Excerpt,
  Typography,
  Time,
} from "@wsui/base";
import React from "react";
import { useTranslation } from "react-i18next";

export default function PostHit(props) {
  props = useThemeProps({ props, name: "AlgoliaPostHit" });
  props = useThemeProps({ props, name: "AlgoliaHit" });
  let {
    hit,
    horizontalContentMaxWidth = "100%",
    hideImage,
    // eslint-disable-next-line no-unused-vars
    hitsOwnerState,
    ...restProps
  } = props;

  const { t } = useTranslation();

  const { title, url, text, contentTypeName, image, publishDate } = hit;

  let contentTypeLabel = t(`contentTypes.${contentTypeName}.name`, {
    count: 1,
  });

  return (
    <TeaserCard
      link={{ url }}
      title={title}
      image={hideImage ? undefined : image || {}}
      mirrored
      mediaColspan={3}
      as="li"
      horizontalContentMaxWidth={horizontalContentMaxWidth}
      {...restProps}
    >
      <CardContent>
        <CardTitle />
        <Excerpt variant="description" lines={2}>
          {text}
        </Excerpt>
        <Typography variant="meta">{contentTypeLabel}</Typography>
        {publishDate && (
          <Typography variant="meta">
            <Time
              date={publishDate}
              locale="sv-SE"
              format={{ dateStyle: "short" }}
            />
          </Typography>
        )}
      </CardContent>
    </TeaserCard>
  );
}
