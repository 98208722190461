import { css } from "@emotion/react";
import { useMenu } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/menus";
import { Link, Icon, useThemeProps, Stack } from "@wsui/base";
import { snakeCase } from "lodash";
import React from "react";

export default function FooterMenu({ inProps }) {
  const { menuName = "footer", ...restProps } = useThemeProps({
    props: inProps,
    name: "FooterMenu",
  });

  const menu = useMenu(snakeCase(menuName).toUpperCase());
  const items = menu?.items;

  if (!items?.length) {
    return null;
  }

  return (
    <nav {...restProps}>
      <Stack direction={"column"}>
        {items.map((item, index) => (
          <Link
            key={index}
            underline={false}
            uri={item.uri}
            url={item.url}
            css={css`
              width: fit-content;
              background: rgba(0, 0, 0, 0.1);
              border-radius: 6px;
              padding: 5px 10px;
              &:hover {
                background-color: rgba(0, 0, 0, 0.25);
                text-decoration: none;
              }
            `}
            className={item.cssClasses}
          >
            {item.icon && <Icon name={item.icon.name} />}
            {item.label}
          </Link>
        ))}
      </Stack>
    </nav>
  );
}
