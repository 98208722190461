import styled from "@emotion/styled";
import { Html } from "@whitespace/gatsby-theme-wordpress-basic/src/wsui/components";
import { ContactsModule as WSUIContactsModule } from "@wsui/municipio";
import React from "react";

import ModuleWrapper from "./ModuleWrapper.jsx";

const HtmlInfoContainer = styled.div`
  & p {
    margin: 0;
  }
`;

export default function ContactsModule({
  module = {},
  title,
  description,
  ...restProps
}) {
  const { modContactsOptions } = module;

  if (!modContactsOptions) {
    return null;
  }

  const modifiedContacts = modContactsOptions.contacts.map((contact) => {
    const {
      address: incomingAddress,
      visitingAddress: incomingVisitingAddress,
      openingHours: incomingOpeningHours,
      other: incomingOther,
    } = contact;

    const address = incomingAddress && <HtmlInfoContainer><Html>{incomingAddress}</Html></HtmlInfoContainer>;
    const visitingAddress = incomingVisitingAddress && <HtmlInfoContainer><Html>{incomingVisitingAddress}</Html></HtmlInfoContainer>;
    const openingHours = incomingOpeningHours && <HtmlInfoContainer><Html>{incomingOpeningHours}</Html></HtmlInfoContainer>;
    const other = incomingOther && <HtmlInfoContainer><Html>{incomingOther}</Html></HtmlInfoContainer>;

    return {
      ...contact,
      address,
      visitingAddress,
      openingHours,
      other,
    };
  });

  return (
    <ModuleWrapper title={title} description={description}>
      <WSUIContactsModule
        contacts={modifiedContacts}
        color="gray.50"
        borderRadius="10px"
        avatarBorder="5px solid"
        avatarBorderColor="gray.152"
        {...restProps}
      />
    </ModuleWrapper>
  );
}
