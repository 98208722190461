import { css, useTheme } from "@emotion/react";
import { useThemeProps } from "@wsui/base";
import { React } from "react";

export default function Table(props) {
  const theme = useTheme();
  const {
    rows,
    rowParity,
    rowParityColor = "#FFFFFF30",
    padding = 0,
    colsAligned = "right",
    fontWeight = 600,
    borderColor = "border",
  } = useThemeProps({
    name: "Table",
    props,
  });

  if (!rows?.length) {
    return null;
  }

  const properties = Object.keys(rows[0]);

  return (
    <table
      css={css`
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
      `}
    >
      <tbody>
        {rows.map((item, rowIndex) => {
          let itemPosition = rowIndex % 2 === 0 ? "even" : "odd";
          return (
            <tr
              key={rowIndex}
              css={css`
                background-color: ${itemPosition === rowParity
                  ? rowParityColor
                  : null};
                &:hover,
                &:focus-visible {
                  background: ${rowIndex > 0 && rowParity ? "#ffffff" : null};
                }
              `}
            >
              {properties.map((property, index) => (
                <td
                  css={css`
                    padding: ${theme.getLength(padding)};
                    border-bottom: 1px ${theme.getColor(borderColor)};
                    border-bottom-style: ${rowIndex < rows.length - 1
                      ? "solid"
                      : "none"};
                    font-weight: ${index === 0 ? fontWeight : null};
                    padding-inline-start: ${index > 0
                      ? theme.getLength(2)
                      : null};
                    padding-inline-end: ${index < properties.length - 1
                      ? theme.getLength(2)
                      : null};
                    padding-block-start: ${rowIndex > 0
                      ? theme.getLength(4)
                      : null};
                    padding-block-end: ${rowIndex < rows.length - 1
                      ? theme.getLength(4)
                      : null};
                    text-align: ${index > 0 ? colsAligned : "left"};
                  `}
                  key={index}
                >
                  {item[property]}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
