/** @jsx jsx */
import { css, jsx } from "@emotion/react";

export default function BreadcrumbsDescription({ description, ...restProps }) {
  return (
    <span {...restProps}>
      <img
        src={"/images/map-pin.svg"}
        css={css`
          display: inline-block;
          vertical-align: -20%;
          width: 14.42px;
          margin-right: 0.5rem;
          @media (max-width: 680px) {
            display: none;
          }
        `}
        aria-hidden="true"
      />
      <span
        css={css`
          font-weight: bold;
        `}
      >
        Du är här
      </span>{" "}
      /
    </span>
  );
}
