/** @jsx jsx */
import { jsx, useTheme } from "@emotion/react";
import { useThemeProps, Link } from "@wsui/base";
import formatDate from "date-fns/format";

import Table from "./Table.jsx";

export default function DayOpeningHours(props) {
  // eslint-disable-next-line no-unused-vars
  const theme = useTheme();
  const {
    day = new Date(), // Defaults to today
    places,
    ...restProps
  } = useThemeProps({
    name: "DayOpeningHours",
    props,
  });

  const items = places?.map((place) => {
    const exception = place?.exceptions?.find(
      (x) => x.date === formatDate(day, "Y-MM-dd"),
    );
    return {
      name: place?.page?.uri ? (
        <Link underline="none" color="primary" uri={place.page.uri}>
          {place.label}
        </Link>
      ) : (
        place.label
      ),
      time: exception
        ? exception.openHours
        : place.defaultOpenHours[`day${day.getDay()}`],
    };
  });

  // TODO: What to do if there are no items?

  return <Table rows={items} {...restProps} />;
}
