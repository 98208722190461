// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-municipio-gatsby-theme-basic-src-whitespace-gatsby-theme-wordpress-basic-wsui-templates-content-type-template-jsx": () => import("./../../../node_modules/@municipio/gatsby-theme-basic/src/@whitespace/gatsby-theme-wordpress-basic/wsui/templates/ContentTypeTemplate.jsx" /* webpackChunkName: "component---node-modules-municipio-gatsby-theme-basic-src-whitespace-gatsby-theme-wordpress-basic-wsui-templates-content-type-template-jsx" */),
  "component---node-modules-whitespace-gatsby-plugin-site-index-src-wsui-components-site-index-template-jsx": () => import("./../../../node_modules/@whitespace/gatsby-plugin-site-index/src/wsui/components/SiteIndexTemplate.jsx" /* webpackChunkName: "component---node-modules-whitespace-gatsby-plugin-site-index-src-wsui-components-site-index-template-jsx" */),
  "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-pages-info-colors-jsx": () => import("./../../../node_modules/@whitespace/gatsby-theme-wordpress-basic/src/pages/__info/colors.jsx" /* webpackChunkName: "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-pages-info-colors-jsx" */),
  "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-pages-wp-preview-js": () => import("./../../../node_modules/@whitespace/gatsby-theme-wordpress-basic/src/pages/wp-preview.js" /* webpackChunkName: "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-pages-wp-preview-js" */),
  "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-wsui-components-wp-restricted-page-jsx": () => import("./../../../node_modules/@whitespace/gatsby-theme-wordpress-basic/src/wsui/components/WpRestrictedPage.jsx" /* webpackChunkName: "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-wsui-components-wp-restricted-page-jsx" */),
  "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-wsui-templates-search-template-jsx": () => import("./../../../node_modules/@whitespace/gatsby-theme-wordpress-basic/src/wsui/templates/SearchTemplate.jsx" /* webpackChunkName: "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-wsui-templates-search-template-jsx" */),
  "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-wsui-templates-template-controller-jsx": () => import("./../../../node_modules/@whitespace/gatsby-theme-wordpress-basic/src/wsui/templates/TemplateController.jsx" /* webpackChunkName: "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-wsui-templates-template-controller-jsx" */),
  "component---src-whitespace-gatsby-theme-wordpress-basic-pages-404-js": () => import("./../../../src/@whitespace/gatsby-theme-wordpress-basic/pages/404.js" /* webpackChunkName: "component---src-whitespace-gatsby-theme-wordpress-basic-pages-404-js" */)
}

