/** @jsx jsx */
import { useTheme, css, jsx } from "@emotion/react";
import {
  Stack,
  TypographyBlock,
  useThemeProps,
  Heading,
  Section,
  handleComponentsProp,
  useIsSameBackground,
} from "@wsui/base";
import {
  PostsModuleArchiveLink,
  postsModuleContext,
  PostsModuleItem,
} from "@wsui/municipio";
import merge from "lodash/fp/merge";
import { useContext } from "react";

export default function PostsModuleListLayout(props) {
  // eslint-disable-next-line no-unused-vars
  let theme = useTheme();
  props = useThemeProps({ props, name: "PostsModuleHorizontalLayout" });
  let {
    title,
    description,
    archiveLink,
    items,
    spacing = 4,
    components,
    headingVariant,
    itemVariant = "card",
    itemColor,
    divider = false,
    ...restProps
  } = props;
  components = handleComponentsProp(components, { Item: PostsModuleItem });
  let { Item } = components;

  let parentOwnerState = useContext(postsModuleContext);
  let { visibleFields } = parentOwnerState;
  let ownerState = merge(parentOwnerState, {
    title,
    description,
    archiveLink,
    items,
    visibleFields,
    itemVariant,
    itemColor,
  });

  let isSameBackground = useIsSameBackground(itemColor);
  divider = !itemColor || isSameBackground ? divider : false;

  return (
    <postsModuleContext.Provider value={ownerState}>
      <div {...restProps}>
        {title && (
          <Heading variant={headingVariant} marginAfter>
            {title}
          </Heading>
        )}
        {description && (
          <TypographyBlock marginAfter>{description}</TypographyBlock>
        )}
        <Section>
          <Stack spacing={spacing} divider={divider}>
            {items.map((item, index) => (
              <Item
                key={index}
                item={item}
                visibleFields={visibleFields}
                variant={itemVariant}
                mirrored={items.length > 1 && index % 2 === 1}
              />
            ))}
          </Stack>
        </Section>
        {archiveLink && (
          <PostsModuleArchiveLink
            css={css`
              margin-top: ${theme.getLength(6)};
            `}
            {...archiveLink}
          />
        )}
      </div>
    </postsModuleContext.Provider>
  );
}
