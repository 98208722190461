/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useThemeProps } from "@wsui/base";
import formatDate from "date-fns/format";

import Table from "./Table.jsx";

const weekdays = [
  "Söndag",
  "Måndag",
  "Tisdag",
  "Onsdag",
  "Torsdag",
  "Fredag",
  "Lördag",
];

export default function WeekOpeningHours(props) {
  const {
    day = new Date(),
    exceptions,
    defaultOpenHours,
    ...restProps
  } = useThemeProps({
    name: "WeekOpeningHours",
    props,
  });

  if (!defaultOpenHours) {
    return null;
  }
  const startDate = day;

  const items = Array.from({ length: 7 }).map((_, index) => {
    const weekday = (index + startDate.getDay()) % 7;
    const theDate = new Date(startDate);
    theDate.setDate(theDate.getDate() + index);

    const exception = exceptions?.find((x) => {
      return x.date === formatDate(theDate, "Y-MM-dd");
    });
    return {
      name: weekday === startDate.getDay() ? "Idag" : weekdays[weekday],
      time: exception ? exception.openHours : defaultOpenHours[`day${weekday}`],
    };
  });

  return <Table rows={items} {...restProps} />;
}
